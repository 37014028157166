namespace $.$$ {
	
	$mol_style_define( $hyoo_shelter_auth_form, {

		Title: {
			justifyContent: 'center',
		},
		Form: {
			margin: 'auto',
		},

	} )

}
