$hyoo_shelter_animal_card $mol_link
	animal $hyoo_shelter_animal / *
	shelters $hyoo_shelter_shelter_list / *
	arg * animal <= id \
	attr *
		^
		gravity_animal_card_gender <= gender \
	sub /
		<= Shelter $mol_view sub / <= shelter_name \
		<= Card $mol_dimmer
			haystack <= card \
			needle <= highlight \
		<= Kind $mol_view sub / <= kind \
		<= Name $mol_dimmer
			haystack <= name \
			needle <= highlight \
		<= Age $mol_view sub / <= age \
		<= Size $mol_view sub / <= size \
