namespace $.$$ {
	
	$mol_style_define( $hyoo_shelter, {

		Auth: {
			flex: {
				grow: 1000,
			},
		},

	} )

}
